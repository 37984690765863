export const appHead = {"meta":[{"charset":"utf-8"},{"name":"google-site-verification","content":"5i1MjB0Egbn8imbyvViXmqBtK-b-wmGQSz-NG9qX7RY"},{"name":"viewport","content":"width=device-width, initial-scale=1, user-scalable=no"}],"link":[{"rel":"preload","href":"/icon-font/iconfont.woff2?t=20250214","type":"font/woff2","as":"font","crossorigin":"anonymous"}],"style":[{"innerHTML":"\n@font-face {\n    font-family: 'Inter';\n    src: url('/fonts/Inter.woff2?t=20250214') format('woff2');\n    font-style: normal;\n    font-weight: 300 800;\n    font-stretch: 100%;\n    font-display: swap;\n    unicode-range: U+0000-00FF;\n}\n/* message 字体图标 */\n@font-face {\n  font-family: iconfont;\n  src: url('/icon-font/iconfont.woff2?t=20250214') format('woff2'),\n       url('/icon-font/iconfont.woff?t=20250214') format('woff'),\n       url('/icon-font/iconfont.ttf?t=20250214') format('truetype');\n}\n"}],"script":[{"src":"https://js.ptengine.com/28tya6z0.js","defer":true},{"src":"https://www.youtube.com/iframe_api","defer":true},{"id":"gtm-script","innerHTML":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TRK39JKQ');","type":"text/javascript","defer":true}],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appPageTransition = {"name":"page","mode":"in-out"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"deep":false}

export const resetAsyncDataToUndefined = false

export const nuxtDefaultErrorValue = undefined

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = true

export const purgeCachedData = true